import { TranslateService } from "@ngx-translate/core";
import { Constants } from "src/app/constants/constants";
import {
    Widget,
    WidgetLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "src/app/components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import {
    Details,
    DetailsLayout
} from "src/app/components/shared/new-details-page/details-section/details-section.component";
import { GraphsService } from "src/app/services/graphs.service";
import { BaseLayout } from "src/app/helpers/base-layout";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "src/app/services/shared.service";
import { ReactFlowDiagramComponent } from "src/app/components/shared/react-flow-diagram/react-flow-diagram.component";
import { ChannelTypes } from "../channel";
import { ChannelDetailsComponent } from "./channel-details/channel-details.component";
import { ZxEventsComponent } from "src/app/components/shared/zx-events/zx-events.component";
import { ZxObjectTrackingMainListComponent } from "src/app/components/shared/zx-object-tracking/main-list/zx-object-tracking-main-list.component";
import { ZxAdvancedNotesComponent } from "src/app/components/shared/zx-advanced-notes/zx-advanced-notes.component";
import { ZxHistoryGraphComponent } from "src/app/components/shared/zx-history-graph/zx-history-graph.component";
import { Source, Tag } from "src/app/models/shared";
import { ChannelBitratesComponent } from "./channel-bitrates/channel-bitrates.component";
import { ChannelTargetsComponent } from "./channel-targets/channel-targets.component";
import { ChannelAdMarkComponent } from "./channel-ad-marks/channel-ad-marks.component";
import { SourceContentAnalysisComponent } from "../../sources/source/source-content-analysis/source-content-analysis.component";
import { AllChannelSourcesComponent } from "./all-channel-sources/all-channel-sources.component";
import { ChannelStreamComponent } from "./channel-stream/channel-stream.component";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export abstract class ChannelLayouts extends BaseLayout {
    keys = {
        primaryDetails: [
            //The order is important for the layout
            "THUMBNAIL",
            "PROCESSING_CLUSTER",
            "ACTIVE_BROADCASTER",
            "AWS_MEDIACONNECT_FLOW",
            "AWS_MEDIALIVE_CHANNEL",
            "PRIORITY",
            // TODO: "PLAY",
            "TAGS"
        ],
        secondaryDetails: [
            //The order is important for the layout
            "TYPE",
            "ALTERNATIVE_CHANNEL",
            "TARGET_BROADCASTER_S",
            "REGION",
            "MODE",
            "PROTOCOLS",
            "ALERTING_PROFILE",
            "CLOSED_CAPTION_PRESERVE",
            "CONTENT_ANALYSIS"
        ],
        nonDeletedSecondaryDetails: [
            "TYPE",
            "ALTERNATIVE_CHANNEL",
            "TARGET_BROADCASTER_S",
            "REGION",
            "MODE",
            "PROTOCOLS",
            "ALERTING_PROFILE"
        ],
        widgets: [
            //The order is important for the layout
            "DIAGRAM",
            "STREAM",
            "HISTORY",
            "BITRATES",
            "SOURCES",
            "TARGETS",
            "AD_MARKERS",
            "ANALYSIS",
            "DETAILS",
            "EVENTS",
            "CHANGES",
            "NOTES"
        ]
    } as const;

    protected scopeName = "channelDetails";
    constants = Constants;
    abstract channel: ChannelTypes;
    abstract canEdit(channel: ChannelTypes): boolean;
    abstract failoverSource?: Source;
    abstract resourceTags: Tag[];
    // TODO maybe need to change it like this: something with .bind(this) fill weird to me...
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    abstract refreshChannelPromise(): Promise<ChannelTypes>;
    constructor(
        protected translate: TranslateService,
        protected graphsService: GraphsService,
        protected userService: UsersService,
        protected sharedService: SharedService
    ) {
        super(userService, sharedService, translate);
    }

    protected translatedNames = {
        ...this.translateList(
            [...this.keys.primaryDetails, ...this.keys.secondaryDetails, ...this.keys.widgets],
            this.translate
        )
    };

    protected primaryDetailsDefaultLayout = this.keys.primaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false
    })) satisfies DetailsLayout<typeof this.keys.primaryDetails>[];

    protected channelPrimaryDetails: Details[] = this.primaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected secondaryDetailsDefaultLayout = this.keys.secondaryDetails.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isHidden: false,
        isDeleted: !this.keys.nonDeletedSecondaryDetails.includes(key as any)
    })) satisfies DetailsLayout<typeof this.keys.secondaryDetails>[];

    protected channelSecondaryDetails: Details[] = this.secondaryDetailsDefaultLayout.map(layout => ({
        ...layout,
        data: [{ title: layout.title, content: "" }]
    }));

    protected widgetHeadersDefaultLayout = this.keys.widgets.map((key, index) => ({
        title: this.translatedNames[key],
        key,
        index,
        isSelected: true,
        isHidden: false,
        isExpanded: false
    })) satisfies WidgetHeaderLayout<typeof this.keys.widgets>[];

    protected channelWidgetHeaders = this.widgetHeadersDefaultLayout.map(layout => ({
        ...layout,
        fa_icon: this.getIconLayoutKey(layout.key)
    })) satisfies WidgetHeader<typeof this.keys.widgets>[];

    protected widgetsDefaultLayout = this.keys.widgets.reduce((obj, key, index) => {
        obj[key] = {
            title: this.translatedNames[key],
            id: this.translatedNames[key],
            key,
            index: index,
            ...this.constants.defaultWidgetLayout
        };
        return obj;
    }, {} as Record<(typeof this.keys.widgets)[number], WidgetLayout>) satisfies Record<
        (typeof this.keys.widgets)[number],
        WidgetLayout
    >;

    protected channelWidgets: Widget[] = [
        {
            ...this.widgetsDefaultLayout.DETAILS,
            component: ChannelDetailsComponent,
            inputs: {
                channel: () => this.channel
            }
        },
        {
            ...this.widgetsDefaultLayout.DIAGRAM,
            component: ReactFlowDiagramComponent,
            inputs: {
                model: () => this.channel,
                type: () => "channel"
            }
        },
        {
            ...this.widgetsDefaultLayout.EVENTS,
            component: ZxEventsComponent,
            inputs: {
                objects: () => this.channel._frontData?.eventsObjects,
                id: () => this.channel.id,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.CHANGES,
            component: ZxObjectTrackingMainListComponent,
            inputs: {
                objectId: () => this.channel.id,
                objectName: () => this.channel.name,
                objectType: () => this.objectTrackingType(),
                objectApiType: () => this.objectTrackingApiType(),
                autoRows: () => false,
                refreshObjectDataFunction: () => this.refreshChannelPromise.bind(this)
            }
        },
        {
            ...this.widgetsDefaultLayout.NOTES,
            component: ZxAdvancedNotesComponent,
            inputs: {
                model: () => this.channel,
                type: () => this.getType(),
                id: () => this.channel.id,
                canEdit: () => this.canEdit(this.channel)
            }
        },
        {
            ...this.widgetsDefaultLayout.HISTORY,
            component: ZxHistoryGraphComponent,
            inputs: {
                object: () => ({ source: this.failoverSource }),
                graphUrl: () =>
                    this.failoverSource ? this.graphsService.custom(this.graphsService.source(this.failoverSource)) : ""
            }
        },
        {
            ...this.widgetsDefaultLayout.BITRATES,
            component: ChannelBitratesComponent,
            inputs: {
                channel: () => this.channel,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.SOURCES,
            component: AllChannelSourcesComponent,
            inputs: {
                channel: () => this.channel,
                source: () => this.failoverSource,
                autoRows: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.TARGETS,
            component: ChannelTargetsComponent,
            inputs: {
                channel: () => this.channel,
                canEdit: () => this.canEdit(this.channel),
                resourceTags: () => this.resourceTags,
                autoRows: () => false,
                bordered: () => false
            }
        },
        {
            ...this.widgetsDefaultLayout.AD_MARKERS,
            component: ChannelAdMarkComponent,
            inputs: {
                channel: () => this.channel
            }
        },
        {
            ...this.widgetsDefaultLayout.ANALYSIS,
            component: SourceContentAnalysisComponent,
            inputs: {
                source: () => this.failoverSource
            }
        },
        {
            ...this.widgetsDefaultLayout.STREAM,
            component: ChannelStreamComponent,
            inputs: {
                channel: () => this.channel,
                source: () => this.failoverSource
            }
        }
    ];

    private getIconLayoutKey(key: (typeof this.keys.widgets)[number]): IconProp {
        switch (key) {
            case "STREAM":
                return "clapperboard-play";
            case "DETAILS":
                return "align-justify";
            case "BITRATES":
                return "table";
            case "SOURCES":
                return "video";
            case "TARGETS":
                return "share";
            case "ANALYSIS":
                return "tasks";
            case "AD_MARKERS":
                return "stream";
            case "DIAGRAM":
                return "sitemap";
            case "HISTORY":
                return "chart-line";
            case "EVENTS":
                return "exclamation-triangle";
            case "CHANGES":
                return "clock-rotate-left";
            case "NOTES":
                return ["far", "sticky-note"];
            default:
                throw new Error("Unknown title"); //TODO should it throw error or return empty string?
        }
    }

    objectTrackingType() {
        return this.channel.adaptive
            ? "adaptive_channel"
            : this.channel.delivery
            ? "delivery_channel"
            : this.channel.failover
            ? "failover_channel"
            : this.channel.mediaconnect
            ? "mediaconnect_flows"
            : "medialive_channels";
    }

    objectTrackingApiType() {
        return this.channel.adaptive
            ? "channels/adaptive"
            : this.channel.delivery
            ? "channels/delivery"
            : this.channel.failover
            ? "channels/failover"
            : this.channel.mediaconnect
            ? "channels/mediaconnect"
            : "channels/medialive";
    }

    getType() {
        return this.channel.adaptive
            ? "adaptiveChannel"
            : this.channel.delivery
            ? "deliveryChannel"
            : this.channel.failover
            ? "failoverChannel"
            : this.channel.mediaconnect
            ? "mediaconnectFlow"
            : "medialiveChannel";
    }
}

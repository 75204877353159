import { Component, OnInit, OnDestroy, ViewChild, inject, ElementRef } from "@angular/core";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { Subscription, interval, BehaviorSubject, firstValueFrom } from "rxjs";
import { filter } from "rxjs/operators";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { UsersService } from "../../account-management/users/users.service";
import { UserPermissions, RecoveryState } from "../../../models/shared";

import { ChannelsService } from "../channels.service";
import { ChannelComponent } from "../channel/channel.component";
import {
    ChannelTypes,
    isMediaConnect,
    isMediaLive,
    isAdaptiveChannel,
    isDeliveryChannel,
    isFailoverChannel
} from "../channel";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { urlBuilder } from "@zixi/shared-utils";
import { SharedService } from "src/app/services/shared.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChannelsDefinitions } from "../channels.definitions";

@Component({
    selector: "app-channel-list",
    templateUrl: "./channel-list.component.html"
})
export class ChannelListComponent implements OnInit, OnDestroy {
    refreshing = false;
    channels: ChannelTypes[];
    channelId: number | null;
    channelName: string | null;
    channelType: string | null;
    selectedChannel: ChannelTypes;
    selectedRows: ChannelTypes[] = [];
    private rawChannelsBS$ = new BehaviorSubject<ChannelTypes[]>([]);
    userPermissions: UserPermissions;
    urls = Constants.urls;

    firstLoad = true;
    currentPageAdaptiveIDs: number[] = [];
    currentPageDeliveryIDs: number[] = [];
    currentPageFlowIDs: number[] = [];
    currentPageMediaLiveIDs: number[] = [];
    currentPageFailoverIDs: number[] = [];

    isResizing: boolean;
    @ViewChild(RouterOutlet) channel: RouterOutlet;
    @ViewChild("leftContainer", { static: true }) leftContainer: ElementRef;

    private routeSubscription: Subscription;
    private channelsSubscription: Subscription;
    private channelsRefreshSubscription: Subscription;
    private splitterPositionSubscription: Subscription;

    splitterPosition;

    fromDate: string;
    toDate: string;
    isInsightRoute: boolean;
    multiSelectDisasterRecovery = false;
    private router = inject(Router);
    private channelsService = inject(ChannelsService);
    private channelsDefinitions = inject(ChannelsDefinitions);
    private modalService = inject(ModalService);
    private userService = inject(UsersService);
    private mixpanelService = inject(MixpanelService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);
    private sharedService = inject(SharedService);
    private broadcasterService = inject(BroadcastersService);
    private ngbModal = inject(NgbModal);

    tableColumnsSchema = this.channelsDefinitions.getTableColumnsSchema();

    constructor() {
        // Set Title
        this.titleService.setTitle("CHANNELS", "");
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (
                    event.snapshot.params &&
                    event.snapshot.params.name &&
                    event.snapshot.params.channelId &&
                    event.snapshot.params.type
                ) {
                    this.channelName = event.snapshot.params.name;
                    this.channelId = urlBuilder.decode(event.snapshot.params.channelId);
                    this.channelType = event.snapshot.params.type;
                    this.isInsightRoute = false;
                } else if (event.snapshot.params && event.snapshot.params.InsightType) {
                    this.isInsightRoute = true;
                } else {
                    this.isInsightRoute = false;
                    this.channelId = null;
                    this.channelName = null;
                    this.channelType = null;
                }

                if (this.channelId) this.updateSelectedChannel();
                else this.selectedRows = [];
            });
    }

    async ngOnInit() {
        this.userPermissions = await firstValueFrom(this.userService.userPermissions);

        // local storage
        if (localStorage.getItem("splitter-width"))
            this.leftContainer.nativeElement.style.flexBasis = localStorage.getItem("splitter-width");

        if (localStorage.getItem("splitter-position"))
            this.sharedService.setSplitterPosition(parseInt(localStorage.getItem("splitter-position") ?? "", 10));

        // subs
        this.splitterPositionSubscription = this.sharedService.getSplitterPosition.subscribe(p => {
            this.splitterPosition = p;
        });

        this.channelsSubscription = this.channelsService.channels.subscribe(channels => {
            this.channels = channels;

            if (this.channels) {
                // First Load
                if (this.firstLoad === true) {
                    this.firstLoad = false;
                    this.prepTableData();
                    this.channelsService.refreshAdaptiveChannels(this.currentPageAdaptiveIDs, true);
                    this.channelsService.refreshDeliveryChannels(this.currentPageDeliveryIDs, true);
                    this.channelsService.refreshMediaConnectFlows(this.currentPageFlowIDs, true);
                    this.channelsService.refreshMediaLiveChannels(this.currentPageMediaLiveIDs, true);
                    // this.channelsService.refreshFailoverChannels(this.currentPageFailoverIDs, true);
                }
                this.prepTableData();
            }
        });

        // Start Auto Refresh
        this.startChannelsRefresh();
        setTimeout(() => {
            if (this.channelName) this.updateSelectedChannel();
            else this.selectedRows = [];
        });
    }

    private updateSelectedChannel() {
        if (!this.channelType) return;
        const channelType = this.channelType;
        const selectedChannel = this.channels?.find((c: ChannelTypes) => {
            if ([Constants.urls.channelTypes.adaptive, Constants.urls.channelTypes.transcoded].includes(channelType))
                return c.adaptive && c.id === this.channelId;
            if ([Constants.urls.channelTypes.delivery].includes(channelType))
                return c.delivery && c.id === this.channelId;
            if ([Constants.urls.channelTypes.mediaconnect].includes(channelType))
                return c.mediaconnect && c.id === this.channelId;
            if ([Constants.urls.channelTypes.medialive].includes(channelType))
                return c.medialive && c.id === this.channelId;
            if ([Constants.urls.channelTypes.failover].includes(channelType))
                return c.failover && c.id === this.channelId;
        });

        if (selectedChannel) {
            this.selectedChannel = selectedChannel;
            this.selectedRows = [this.selectedChannel];
        }
    }

    get allChannelObservable() {
        return this.rawChannelsBS$;
    }

    getServerBroadcasterId(channel: ChannelTypes) {
        let broadcasterId: number | null = 0;
        if (isAdaptiveChannel(channel)) {
            broadcasterId = channel.activeBroadcasterObjects?.bx_id ?? channel.broadcaster_id;
        } else if (isDeliveryChannel(channel)) {
            broadcasterId =
                channel.activeBroadcasterObjects &&
                channel.activeBroadcasterObjects[0] &&
                channel.activeBroadcasterObjects[0].bx_id
                    ? channel.activeBroadcasterObjects[0].bx_id
                    : channel.target_broadcaster_id;
        } else if (isFailoverChannel(channel)) {
            broadcasterId = channel.activeBroadcasterObjects?.bx_id ?? channel.deliveryChannel?.target_broadcaster_id;
        }
        return broadcasterId && broadcasterId > 0 ? broadcasterId : null;
    }

    getAllChannelsBroadcasters() {
        const broadcasterIds = new Set<number>();
        this.channels.forEach(async channel => {
            const broadcasterId = this.getServerBroadcasterId(channel);
            if (!!broadcasterId) {
                broadcasterIds.add(broadcasterId);
            }
        });

        broadcasterIds.forEach(async broadcasterId => {
            const broadcaster = this.broadcasterService.getCachedBroadcaster(broadcasterId);
            if (!broadcaster) await firstValueFrom(this.broadcasterService.refreshBroadcaster(broadcasterId));
        });
    }

    private prepTableData() {
        if (this.channels) {
            const channels = [...this.channels.filter(c => !c.is_hidden)];
            this.rawChannelsBS$.next(channels);

            this.getAllChannelsBroadcasters();
            this.channels.forEach(async channel => {
                const broadcasterId = this.getServerBroadcasterId(channel);
                if (!channel._frontData?.active_broadcaster && !!broadcasterId) {
                    const cachedBroadcaster = this.broadcasterService.getCachedBroadcaster(broadcasterId);
                    if (cachedBroadcaster) channel._frontData.active_broadcaster = cachedBroadcaster;
                }
            });
        }
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.channelsSubscription.unsubscribe();
        this.splitterPositionSubscription.unsubscribe();
        this.stopChannelsRefresh();
    }

    resizing(isResizing: boolean) {
        this.isResizing = isResizing;
    }

    onPagination(channels: Array<ChannelTypes>) {
        this.currentPageAdaptiveIDs = channels.filter(c => c.adaptive).map(c => c.id);
        this.currentPageDeliveryIDs = channels.filter(c => c.delivery).map(c => c.id);
        this.currentPageFlowIDs = channels.filter(c => c.mediaconnect).map(c => c.id);
        this.currentPageMediaLiveIDs = channels.filter(c => c.medialive).map(c => c.id);
        this.currentPageFailoverIDs = channels.filter(c => c.failover).map(c => c.id);
    }

    onSort(s: string) {
        this.channelsDefinitions.currentTableSortDirection = s;
    }

    selectRow = (channel: ChannelTypes): void => {
        this.selectedChannel = channel;

        if (channel.mediaconnect) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.mediaconnect, channel.name)
            );
        } else if (channel.adaptive) {
            if (channel.is_transcoding)
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.transcoded, channel.name)
                );
            else
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.adaptive, channel.name)
                );
        } else if (channel.delivery) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.delivery, channel.name)
            );
        } else if (channel.medialive) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.medialive, channel.name)
            );
        } else if (channel.failover) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.failover, channel.name)
            );
        }
    };

    async refresh() {
        if (this.isInsightRoute) return;

        this.refreshing = true;

        const adaptiveChannelsRefresh = firstValueFrom(
            this.channelsService.refreshAdaptiveChannels(
                this.currentPageAdaptiveIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.adaptive || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const deliveryChannelsRefresh = firstValueFrom(
            this.channelsService.refreshDeliveryChannels(
                this.currentPageDeliveryIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.delivery || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const mediaconnectFlowsRefresh = firstValueFrom(
            this.channelsService.refreshMediaConnectFlows(
                this.currentPageFlowIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.mediaconnect || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const mediaLiveChannelsRefresh = firstValueFrom(
            this.channelsService.refreshMediaLiveChannels(
                this.currentPageMediaLiveIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.medialive || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const failoverChannelsRefresh = firstValueFrom(
            this.channelsService.refreshFailoverChannels(
                this.currentPageFailoverIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.failover || id !== this.selectedChannel.id
                ),
                true
            )
        );

        const channelRefresh =
            this.channelName && this.channelType && this.channelId
                ? this.channelsService.refreshChannel(this.selectedChannel, true).toPromise()
                : null;
        const channelComponentRefresh =
            this.channel && this.channel.component ? (this.channel.component as ChannelComponent)?.refresh() : null;

        await Promise.all([
            adaptiveChannelsRefresh,
            deliveryChannelsRefresh,
            mediaconnectFlowsRefresh,
            mediaLiveChannelsRefresh,
            failoverChannelsRefresh,
            channelRefresh,
            channelComponentRefresh
        ]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (channel: ChannelTypes) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "CHANNEL", this.selectedRows, func);
        if (!result.keepSelected) this.selectedRows = [];
        if (result.actionTaken)
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple channels");
    }

    async multiEnable() {
        let transcoded = false;
        this.selectedRows.forEach((channel: ChannelTypes) => {
            if (channel.adaptive && channel.is_transcoding) transcoded = true;
        });
        //
        if (transcoded) {
            const result = await this.modalService.confirmMultiple(
                "ENABLE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes, igt: boolean) => {
                    return this.channelsService.updateChannel(channel, {
                        is_enabled: true,
                        ignore_transcode_thresholds: igt
                    });
                },
                {
                    checkbox: "IGNORE_TRANSCODE_THRESHOLDS"
                }
            );
            if (!result.keepSelected) this.selectedRows = [];
            if (result.actionTaken)
                this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " multiple channels");
        } else {
            const result = await this.modalService.confirmMultiple(
                "ENABLE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes) => this.channelsService.updateChannel(channel, { is_enabled: true })
            );
            if (!result.keepSelected) this.selectedRows = [];
            if (result.actionTaken)
                this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " multiple channels");
        }
    }

    async multiDelete() {
        let mediaconnectFlow = false;
        this.selectedRows.forEach(channel => {
            if (channel.mediaconnect) mediaconnectFlow = true;
        });
        if (mediaconnectFlow) {
            const result = await this.modalService.confirmMultiple(
                "DELETE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes, awsRelease: boolean) =>
                    this.channelsService.deleteChannel(channel, awsRelease),
                {
                    checkbox: this.translate.instant(
                        "DELETE_MEDIACONNECT_FLOW_IN_ZEN_MASTER_LEAVE_IT_IN_AWS_MEDIACONNECT"
                    )
                    // deleteInput: true
                }
            );
            if (result.actionTaken) {
                this.mixpanelService.sendEvent(this.translate.instant("DELETE").toLowerCase() + " multiple channels");
                this.selectedRows = [];
            }
        } else {
            const result = await this.modalService.confirmMultiple(
                "DELETE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes) => this.channelsService.deleteChannel(channel)
            );
            if (result.actionTaken) {
                this.mixpanelService.sendEvent(this.translate.instant("DELETE").toLowerCase() + " multiple channels");
                this.selectedRows = [];
            }
        }
    }

    async multiEdit() {
        await this.modalService.editMultiple("CHANNEL", this.selectedRows, async (channel: ChannelTypes, model) => {
            return this.channelsService.updateChannel(channel, model);
        });
    }

    multiToggleState(enable: boolean) {
        this.multiAction(enable ? "ENABLE" : "DISABLE", async (channel: ChannelTypes) =>
            this.channelsService.updateChannel(channel, { is_enabled: enable })
        );
    }

    multiToggleMute(mute: boolean): void {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async (channel: ChannelTypes) =>
            this.channelsService.updateChannel(channel, {
                muted: mute,
                muted_until: null,
                flapping: null
            })
        );
    }

    startChannelsRefresh() {
        this.channelsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopChannelsRefresh() {
        this.channelsRefreshSubscription.unsubscribe();
    }

    onSelectedRowsChange(selectedRows: ChannelTypes[]) {
        this.multiSelectDisasterRecovery = false;

        const isSelectionIncludeMediaChannels = Boolean(
            selectedRows.find(row => isMediaConnect(row) || isMediaLive(row))
        );

        const isSelectionDRable = Boolean(
            selectedRows.find(
                row =>
                    this.channelsService.getDisasterRecoveryState(row) !== RecoveryState.none &&
                    (row.delivery || row.adaptive || row.failover)
            )
        );

        this.multiSelectDisasterRecovery = isSelectionDRable && !isSelectionIncludeMediaChannels;
    }

    disasterRecoveryClick() {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = this.selectedRows
            .filter(row => !row.mediaconnect)
            .map(s => ({
                id: s.id,
                type: s.failover ? "failover_channel" : s.delivery ? "delivery_channel" : "adaptive_channel"
            }));
    }
}

import { Component, OnInit, OnDestroy, ViewChild, inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, firstValueFrom } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";

import { SharedService } from "src/app/services/shared.service";
import { ChannelsService } from "../channels.service";

import {
    MediaConnectFlow,
    MediaConnectMediaStream,
    MediaConnectSource,
    MediaConnectVpcInterface
} from "src/app/models/shared";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import {
    AmazonAWSAvailabilityZone,
    AmazonAWSIAMRole,
    AmazonAWSRegionDetails,
    AmazonAWSSecurityGroup,
    AmazonAWSSubnet
} from "../../configuration/amazon-aws/amazon-aws";
import { MediaConnectSourcesService } from "../../sources/mediaconnect-sources.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import {
    FullMediaconnectMediaStream,
    sourceUpdated as sourceUpdatedOnMediaStream
} from "../../../components/shared/zx-mediaconnect-mediastream/zx-mediaconnect-mediastream.component";
import { validateVPCTypes } from "../../../components/shared/zx-mediaconnect-vpc/zx-mediaconnect-vpc.component";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

function notNull<T>(x: T | null | undefined): x is T {
    return x != null;
}

@Component({
    selector: "app-channel-mediaconnect-flow-form",
    templateUrl: "./channel-form.component.html"
})
export class ChannelFormMediaConnectFlowComponent implements OnInit, OnDestroy {
    channel: MediaConnectFlow;
    channels: MediaConnectFlow[];
    channelId: number;
    channelName: string;
    channelRegion: string;

    sources: MediaConnectSource[] = [];
    selectedSource: MediaConnectSource | null = null;
    selectedSourceIsJPEGXS = false;
    sourceVPCsAmount = 1;

    vpcInterfaces: MediaConnectVpcInterface[] = [];
    readyVPCInterfaces: string[] = [];
    mediaStreams: FullMediaconnectMediaStream[] = [];
    readyMediaStreamNames: string[] = [];
    mediaStreamIDs: number[] = [];
    mediaStreamPorts: number[] = [];
    sourceVpcInterfaces: string[] = [];

    objectType: string | null = null;
    objectCluster: string | null = null;
    objectName: string | null = null;

    existingChannel: MediaConnectFlow;

    loading = true;
    saving = false;
    vpcLoadInProgress = false;

    import = false;
    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    sourceChangeRequired = false;
    elementalLinkSourceWithoutTransodingProfile = false;
    mediaStreamVideoStreams = 0;
    mediaStreamDataStreams = 0;
    constants = Constants;
    awsZones: AmazonAWSAvailabilityZone[] = [];
    awsSubnets: (AmazonAWSSubnet & { name: string })[] = [];
    awsSubnetOptions: (AmazonAWSSubnet & { name: string })[] = [];
    region: AmazonAWSRegionDetails;

    awsIAMRolesLoading = false;
    awsRegionLoading = false;
    awsRegionDetailsLoading = false;
    awsIAMRoles: AmazonAWSIAMRole[] = [];

    awsSecurityGroups: AmazonAWSSecurityGroup[] = [];

    totalENAVPCsCount = 0;
    totalEFAVPCsCount = 0;
    mediaStreamsRequired = false;
    vpcsRequired = false;
    originalMaxVPCElementID = -1;
    originalMaxMediaStreamElementID = -1;
    channelRefreshInitiated = false;

    currentSection = "info";

    private selectedMediaconnectSourceId: number;
    get mediaconnectSourceId() {
        return this.selectedMediaconnectSourceId;
    }
    set mediaconnectSourceId(v: number) {
        this.selectedMediaconnectSourceId = v;
        this.updateSourceChangeRequired();
    }

    private channelsSubscription: Subscription;
    private mcSourcesSubscription: Subscription;

    @ViewChild("form") form: NgForm;
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern("^[a-zA-Z0-9_-]{2,64}$"),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    enableThumbnailsControl = new UntypedFormControl({ value: false }, []);
    frozenFrameDetectionControl = new UntypedFormControl("", [Validators.min(10), Validators.max(60)]);
    blackFrameDetectionControl = new UntypedFormControl("", [Validators.min(10), Validators.max(60)]);
    silentAudioDetectionControl = new UntypedFormControl("", [Validators.min(10), Validators.max(60)]);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private cs = inject(ChannelsService);
    private mcs = inject(MediaConnectSourcesService);
    private aas = inject(AmazonAwsService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private location = inject(Location); // constructor(

    prepForm() {
        if (this.isEdit || this.isClone) {
            this.tagsControl.setValue(this.channel.resourceTags);
            this.frozenFrameDetectionControl.setValue(this.channel.frozen_frame_detection);
            this.blackFrameDetectionControl.setValue(this.channel.black_frame_detection);
            this.silentAudioDetectionControl.setValue(this.channel.silent_audio_detection);
            this.enableThumbnailsControl.setValue(this.channel.enable_thumbnails);
            if (this.isEdit) {
                this.nameControl.setValue(this.channel.name);
            } else if (this.isClone) {
                this.channel.name = "";
                this.channel.muted = this.channel.active_mute ? 1 : 0;
                this.channel.source = undefined;
                this.selectedSource = null;
            }

            if (this.channel) {
                this.vpcLoadInProgress = true;
                if (this.channel.source) {
                    this.mediaconnectSourceId = this.channel.source?.id ?? undefined;
                    this.selectedSource = this.channel.source;
                }

                this.updateAWSAccount()
                    .then(() => {
                        return this.updateAWSIAMRoles();
                    })
                    .then(() => {
                        this.vpcInterfaces = this.channel.vpc;
                        this.originalMaxVPCElementID = Math.max(
                            ...(this.channel.vpc?.map(v => v.id).filter(notNull) ?? [])
                        );
                        this.vpcPropertiesChanged();
                        this.validateVPCTypeForSource();
                        this.vpcLoadInProgress = false;
                        //  Assuming by this time sources are loaded.
                        this.mediaStreams = (
                            (this.channel.source ? this.channel.source.mediaStreams : this.channel.mediaStreams) ?? []
                        ).map(s => ({
                            ...s,
                            inUse: true
                        }));
                        this.originalMaxMediaStreamElementID = Math.max(
                            ...(this.channel.mediaStreams?.map(s => s.id).filter(notNull) ?? [])
                        );

                        this.sourceSelected();
                        this.sourceVpcInterfaces = (this.channel?.source?.vpc ?? [])
                            .map(vpc => vpc.name)
                            .filter(notNull);
                        this.vpcLoadInProgress = false;
                    });
            } else {
                this.vpcLoadInProgress = false;
            }
        }

        if (!this.channel && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        // Set Title
    }

    onAwsZonesChange(awsZones: AmazonAWSAvailabilityZone[]) {
        this.awsZones = awsZones;
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);
        this.frozenFrameDetectionControl.setValue(null);
        this.blackFrameDetectionControl.setValue(null);
        this.silentAudioDetectionControl.setValue(null);
        this.enableThumbnailsControl.setValue(false);

        // Channel
        this.channel = new MediaConnectFlow();
        this.vpcInterfaces = [];
        this.mediaStreams = [];
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.channelId = urlBuilder.decode(params.channelId);
        this.channelName = params.name;
        this.channelRegion = params.region;

        this.titleService.setTitle("CHANNEL", params.action, this.channel);

        if (params.action) {
            if (params.action === "edit") this.isEdit = true;
            else if (params.action === "clone") this.isClone = true;
        }

        this.objectType = params.objecttype;
        this.objectCluster = params.objectcluster;
        this.objectName = params.objectname;

        if (this.channelName && this.channelId) {
            this.channel = Object.assign({}, this.cs.getCachedMediaConnectFlow(this.channelId));
            this.existingChannel = _.cloneDeep(this.channel);

            // Check if channel found in cache, if not get channels and channel
            if (this.sharedService.isEmptyObject(this.channel) || !this.channel.hasFullDetails) {
                this.channelRefreshInitiated = true;
                await firstValueFrom(this.cs.getMediaConnectFlows());
                this.channel = Object.assign({}, this.cs.getCachedMediaConnectFlow(this.channelId));
                const channel = await this.cs.getMediaConnectFlow(this.channel.id);
                if (channel) this.channel = Object.assign({}, channel);
                this.existingChannel = _.cloneDeep(this.channel);
            }
        } else {
            this.cs.getMediaConnectFlows();
        }
        this.loading = false;

        // Channels
        if (!this.channelRefreshInitiated) {
            this.cs.getMediaConnectFlows();
        }

        // Channels
        this.channelsSubscription = this.cs.mediaconnectFlows.subscribe(channels => {
            this.channels = channels;
        });

        // Mediaconnect Sources
        this.mcSourcesSubscription = this.mcs.mediaconnectSources.subscribe(sources => {
            this.sources = sources;

            if (this.objectType && this.objectCluster && this.objectName) {
                const source = this.sources.find(source => source.name === this.objectName);

                if (source && source.id) {
                    this.mediaconnectSourceId = source.id;
                    this.selectedSource = source;
                }
            }
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.channelsSubscription.unsubscribe();
        this.mcSourcesSubscription.unsubscribe();
    }

    addVpcInterface() {
        // Add id
        let highestID = 0;
        this.vpcInterfaces.forEach(i => {
            if (i.id) {
                if (i.id >= highestID) {
                    highestID = i.id + 1;
                }
            } else {
                highestID = highestID + 1;
            }
        });

        //  In case an existing VPC was deleted as part of edit,
        //  and then a new one added, it should have higher ID
        if (highestID <= this.originalMaxVPCElementID) highestID = this.originalMaxVPCElementID + 1;

        this.vpcInterfaces.push({
            id: highestID,
            name: null,
            role_arn: null,
            security_group_ids: null,
            subnet_id: null,
            network_interface_type: "ena"
        });
    }

    addMediaStream() {
        // Add id
        let highestID = 0;
        this.mediaStreams.forEach(i => {
            if (i.id) {
                if (i.id >= highestID) {
                    highestID = i.id + 1;
                }
            } else {
                highestID = highestID + 1;
            }
        });

        //  In case an existing MediaStream  was deleted as part of edit,
        //  and then a new one added, it should have higher ID
        if (highestID <= this.originalMaxMediaStreamElementID) highestID = this.originalMaxMediaStreamElementID + 1;

        this.mediaStreams.push({
            id: highestID,
            name: null,

            stream_id: null,
            stream_type: "video",

            clock_rate: 90000,

            // audio attributes
            audio_language: "",
            audio_channel_order: "SMPTE2110.(ST)",

            // video attributes
            video_format: "1080p",
            video_colorimetry: "BT709",
            video_exact_frame_rate: "60000/1001",
            video_par: "1:1",
            video_range: "NARROW",
            video_scan_mode: "progressive",
            video_tcs: "SDR",
            inUse: true,
            encoding_name: this.selectedSource?.protocol === "st2110-jpegxs" ? "jxsv" : "raw"
        });
    }

    async onSubmit() {
        if (
            this.totalENAVPCsCount > 2 ||
            this.totalEFAVPCsCount > 1 ||
            this.mediaStreamsRequired ||
            this.vpcsRequired
        ) {
            return;
        }

        if (this.selectedSource?.elemental_link_id && !this.selectedSource.transcoding_profile_id) {
            this.elementalLinkSourceWithoutTransodingProfile = true;
            return;
        } else {
            this.elementalLinkSourceWithoutTransodingProfile = false;
        }
        this.saving = true;

        if (this.isEdit) {
            const model = {
                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.channel.alertingProfile.id,
                mediaconnect_source_id: this.mediaconnectSourceId,
                vpc_interfaces: this.vpcInterfaces.map(vpcInterface => ({
                    name: vpcInterface.name,
                    role_arn: vpcInterface.role_arn,
                    security_group_ids: vpcInterface.security_group_ids,
                    subnet_id: vpcInterface.subnet_id,
                    network_interface_type: vpcInterface.network_interface_type
                })),
                source_vpc_interfaces: this.sourceVpcInterfaces,
                media_streams: this.mediaStreams.map(({ id, inUse, ...params }) => params), // eslint-disable-line @typescript-eslint/no-unused-vars
                description: this.channel.description,
                enable_thumbnails: this.enableThumbnailsControl.value,
                frozen_frame_detection: this.frozenFrameDetectionControl.value,
                black_frame_detection: this.blackFrameDetectionControl.value,
                silent_audio_detection: this.silentAudioDetectionControl.value
            };

            const objects = {
                mediaconnect_source_id: { objectsKey: "source", valuePath: "id" }
            };
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingChannel, [], objects);

            const newVPCInterfaces = this.compareVPCInterfacess(this.existingChannel, model);
            if (!newVPCInterfaces) delete changedData["vpc_interfaces"];
            else Object.assign(changedData, { vpc_interfaces: newVPCInterfaces });

            const newMediaStreams = this.compareMediaStreams(this.existingChannel, model);
            if (!newMediaStreams) delete changedData["media_streams"];
            else Object.assign(changedData, { media_streams: newMediaStreams });

            const newSourceVpcInterfaces = this.compareSourceVPCInterfaces(this.existingChannel, model);
            if (!newSourceVpcInterfaces) delete changedData["source_vpc_interfaces"];
            else Object.assign(changedData, { source_vpc_interfaces: newSourceVpcInterfaces });

            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedChannel = await this.cs.updateChannel(this.channel, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedChannel;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "CHANNEL",
                        async () => {
                            const updateAndRestartChannel = await this.cs.updateChannel(this.channel, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartChannel) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart mediaconnect flow channel", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularChannelUrl(
                                        this.channel.id,
                                        Constants.urls.channelTypes.mediaconnect,
                                        this.channelName
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.channelName
                    );
                    this.saving = false;
                } else if (updatedChannel) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update mediaconnect flow channel", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularChannelUrl(
                            this.channel.id,
                            Constants.urls.channelTypes.mediaconnect,
                            this.channelName
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(
                        this.channel.id,
                        Constants.urls.channelTypes.mediaconnect,
                        this.channelName
                    )
                );
            }
        } else {
            const model = {
                name: this.nameControl.value,

                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.channel.alertingProfile.id,
                aws_account_id: this.channel.aws_account_id,
                region: this.channel.region,
                availability_zone:
                    this.channel.availability_zone !== "random"
                        ? this.channel.availability_zone
                        : _.sample(this.awsZones.filter(z => z.id !== "random"))?.id,
                mediaconnect_source_id: this.mediaconnectSourceId,
                arn: this.import ? this.channel.arn : undefined,
                muted: this.channel.muted,
                vpc_interfaces: this.vpcInterfaces.map(vpcInterface => ({
                    name: vpcInterface.name,
                    role_arn: vpcInterface.role_arn,
                    security_group_ids: vpcInterface.security_group_ids,
                    subnet_id: vpcInterface.subnet_id,
                    network_interface_type: vpcInterface.network_interface_type
                })),
                source_vpc_interfaces: this.sourceVpcInterfaces,
                media_streams: this.mediaStreams.map(({ id, inUse, ...params }) => params), // eslint-disable-line @typescript-eslint/no-unused-vars
                description: this.channel.description,
                enable_thumbnails: this.enableThumbnailsControl.value,
                frozen_frame_detection: this.frozenFrameDetectionControl.value,
                black_frame_detection: this.blackFrameDetectionControl.value,
                silent_audio_detection: this.silentAudioDetectionControl.value
            };

            const result = await this.cs.addChannel(model, "mediaconnect");
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create mediaconnect flow");
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(result.id, Constants.urls.channelTypes.mediaconnect, model.name)
                );
            } else this.saving = false;
        }
    }

    cancel() {
        if (!this.isEdit && !this.isClone) return this.router.navigate([Constants.urls.channels]);
        this.router.navigate(
            urlBuilder.getRegularChannelUrl(
                this.channel.id,
                Constants.urls.channelTypes.mediaconnect,
                this.existingChannel.name
            )
        );
    }

    back() {
        this.location.back();
    }

    compareVPCInterfacess(existingChannel: MediaConnectFlow, model) {
        const reducedOriginal = _.map(existingChannel.vpc, vpc => {
            return _.pick(vpc, "name", "role_arn", "security_group_ids", "subnet_id", "network_interface_type");
        });
        if (_.isEqual(reducedOriginal, model.vpc_interfaces)) {
            return false;
        } else {
            return model.vpc_interfaces;
        }
    }

    compareMediaStreams(existingChannel: MediaConnectFlow, model) {
        const reducedOriginal = _.map(existingChannel.mediaStreams, ms => {
            return _.omit(ms, ["id"]);
        });
        if (_.isEqual(reducedOriginal, model.media_streams)) {
            return false;
        } else {
            return model.media_streams;
        }
    }

    compareSourceVPCInterfaces(existingChannel: MediaConnectFlow, model) {
        const reducedOriginal = (existingChannel.source?.vpc ?? []).map(vpc => vpc.name);
        if (_.isEqual(reducedOriginal, model.source_vpc_interfaces)) {
            return false;
        } else {
            return model.source_vpc_interfaces;
        }
    }

    async updateAWSAccount() {
        if (this.channel.aws_account_id == null) return;
        await this.updateAWSIAMRoles();
    }

    async updateAWSRegion(region: AmazonAWSRegionDetails) {
        this.region = region;
        this.updateSubnetsAndSecurityGroups();
    }

    updateSubnetsAndSecurityGroups() {
        this.awsSubnets = this.region.subnets.map(subnet => ({
            ...subnet,
            name: `${subnet.vpc_id} - ${subnet.id} - ${subnet.cidr} - ${subnet.az}`
        }));
        this.awsSubnetOptions =
            this.channel.availability_zone !== "random"
                ? this.awsSubnets.filter(subnet => subnet.az === this.channel.availability_zone)
                : this.awsSubnets;
        this.awsSecurityGroups = this.region.security_groups;
    }

    async updateAWSIAMRoles() {
        this.awsIAMRoles = [];

        if (this.channel.aws_account_id == null) return;

        this.awsIAMRolesLoading = true;

        this.awsIAMRoles = (await this.aas.getAWSAccountIAMRoles(this.channel.aws_account_id)).filter(
            role => role.mediaconnect
        );

        this.awsIAMRolesLoading = false;
    }

    async updateSourceChangeRequired() {
        const selectedSource = this.sources.find(s => s.id === this.mediaconnectSourceId);
        this.sourceChangeRequired = !!(
            selectedSource &&
            selectedSource.feeder_id == null &&
            selectedSource.broadcaster_id == null &&
            !["st2110-jpegxs", "cdi"].includes(selectedSource.protocol) &&
            this.existingChannel &&
            (!this.existingChannel?.source || this.existingChannel?.source.id !== this.mediaconnectSourceId)
        );
    }

    vpcPropertiesChanged() {
        if (this.vpcInterfaces) {
            this.readyVPCInterfaces = this.vpcInterfaces
                .map(vpc => vpc.name?.trim() || "")
                .filter(vpcName => vpcName && vpcName.length > 0)
                .filter((name, index, arr) => arr.indexOf(name) === index);

            if (this.selectedSource && this.selectedSource.protocol === "cdi") {
                this.assignVPCtoCDISource();
            }

            this.validateVPCTypeForSource();
            for (let index = 0; index < this.vpcInterfaces.length; index++) {
                const control = this.form.controls[`vpc_interface_${index}`];
                if (!control) continue;
                control.updateValueAndValidity();
            }
        }
    }

    vpcRemoved(vpc: MediaConnectVpcInterface) {
        const index = this.vpcInterfaces.indexOf(vpc);

        if (index >= 0) this.vpcInterfaces.splice(index, 1);

        this.readyVPCInterfaces = this.vpcInterfaces
            .map(vpc => vpc.name?.trim() || "")
            .filter(vpcName => vpcName && vpcName.length > 0)
            .filter((name, index, arr) => arr.indexOf(name) === index);

        if (this.channel && this.sourceVpcInterfaces) {
            this.sourceVpcInterfaces = this.sourceVpcInterfaces.filter(name => this.readyVPCInterfaces.includes(name));
        }

        if (this.selectedSource && this.selectedSource.protocol === "cdi") {
            this.assignVPCtoCDISource();
        }

        this.validateVPCTypeForSource();
    }

    mediaStreamChanged() {
        this.readyMediaStreamNames = this.mediaStreams
            .map(strm => strm.name?.trim() || "")
            .filter(strmName => strmName && strmName.length > 0)
            .filter((name, index, arr) => arr.indexOf(name) === index);
        this.mediaStreamIDs = this.mediaStreams.map(strm => strm.stream_id).filter(notNull);
        this.mediaStreamPorts = this.mediaStreams.map(strm => strm.port).filter(notNull);
        this.mediaStreamDataStreams = countInArray(this.mediaStreams, strm => strm.stream_type === "ancillary-data");
        this.mediaStreamVideoStreams = countInArray(this.mediaStreams, strm => strm.stream_type === "video");
        this.validateMediaStreams();
    }

    validateMediaStreams() {
        this.mediaStreamsRequired =
            ["cdi", "st2110-jpegxs"].includes(this.selectedSource?.protocol ?? "") && this.mediaStreams.length === 0;
        for (let index = 0; index < this.mediaStreams.length; index++) {
            const control = this.form.controls[`mediastream_${index}`];
            if (!control) continue;
            control.updateValueAndValidity();
        }
    }

    mediaStreamRemoved(strm) {
        const index = this.mediaStreams.indexOf(strm);
        if (index >= 0) this.mediaStreams.splice(index, 1);
        this.validateMediaStreams();
    }

    sourceSelected() {
        const selectedSource = this.sources.find(src => src.id === this.selectedMediaconnectSourceId);
        if (!selectedSource) return;
        this.selectedSource = selectedSource;

        this.selectedSourceIsJPEGXS = this.selectedSource.protocol === "st2110-jpegxs";
        this.sourceVPCsAmount = this.selectedSourceIsJPEGXS ? 2 : 1;
        if (this.selectedSource.protocol === "cdi") this.assignVPCtoCDISource();
        else this.sourceVpcInterfaces = [];

        for (const strm of this.mediaStreams ?? []) {
            sourceUpdatedOnMediaStream(strm, this.selectedSource);
        }
        this.validateVPCTypeForSource();
        this.validateMediaStreams();
    }

    assignVPCtoCDISource() {
        this.sourceVpcInterfaces = this.vpcInterfaces
            .filter(vpc => vpc.network_interface_type === "efa")
            .map(v => v.name)
            .filter(notNull);
    }

    sourceVPCSelectionChanged() {
        this.validateVPCTypeForSource();
        this.validateMediaStreams();
    }

    validateVPCTypeForSource() {
        const counts = validateVPCTypes(this.vpcInterfaces);
        this.totalEFAVPCsCount = counts.efaCount;
        this.totalENAVPCsCount = counts.enaCount;

        this.vpcsRequired =
            ["cdi", "st2110-jpegxs"].includes(this.selectedSource?.protocol ?? "") && this.vpcInterfaces.length === 0;
    }

    // Error Code
    infoHasErrors() {
        return this.nameControl.invalid || this.tagsControl.invalid;
    }

    configHasErrors(form: NgForm) {
        if (form.form.controls.aws_account_id && form.form.controls.aws_account_id.invalid === true) return true;
        if (form.form.controls.region && form.form.controls.region.invalid === true) return true;
        if (form.form.controls.zone && form.form.controls.zone.invalid === true) return true;
        if (form.form.controls.source && form.form.controls.source.invalid === true) return true;
        if (form.form.controls.sourceVpcInterfaces && form.form.controls.sourceVpcInterfaces.invalid === true)
            return true;
        if (form.form.controls.arn && form.form.controls.arn.invalid === true) return true;
        //
        if (this.sourceChangeRequired) return true;
        return false;
    }

    vpcHasErrors(form: NgForm) {
        if (form.form.controls.vpc_0 && form.form.controls.vpc_0.invalid === true) return true;
        if (form.form.controls.vpc_1 && form.form.controls.vpc_1.invalid === true) return true;
        if (form.form.controls.vpc_2 && form.form.controls.vpc_2.invalid === true) return true;
        return false;
    }

    mediasHasErrors(form: NgForm) {
        for (const control in form.form.controls) {
            if (control.includes("media_stream_")) {
                if (form.form.controls[control] && form.form.controls[control].invalid === true) return true;
            }
        }
        return false;
    }

    filterOutElementalLinkSources(): (s: MediaConnectSource) => boolean {
        return (s: MediaConnectSource): boolean => {
            return !s.elemental_link_id;
        };
    }

    // Scroll Spy Code
    onSectionChange(section: string) {
        this.currentSection = section;
    }

    scrollTo(section: string) {
        document.querySelector("#" + section)?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
}

function countInArray(arr: unknown[], check: (unknown) => boolean): number {
    let acc = 0;
    for (const v of arr) {
        acc += check(v) ? 1 : 0;
    }
    return acc;
}
